import { useEffect, useState } from "react";
import {
  useReadOnlyWindowScrollY,
  useReadOnlyWindowSize,
} from "../window/scroll";
import { useIsMobile } from "../../_layout/window";
import { getRangeOfNumber } from "../utils/getRangeOfNumber";
import { useRouter } from "next/router";
import { atom, useAtom } from "jotai";
import {
  gnbMobileHeight,
  gnbTabletHeight,
  lnbMobileHeight,
  lnbTabletHeight,
} from "@/styles/variables";
import {
  useIsTargetBlogCategory,
  useIsTargetPage,
  useIsTargetStudyClubPage,
} from "../../_layout/gnb/v2";
import { activeLnbItemOffsetRightAtom } from "../../../stores/_layout/lnb";

type Indicator = { left: number; width: number };

export const useOffsetTopList = (sectionElemList) => {
  const [offsetTopList, setOffsetTopList] = useState([
    ...getRangeOfNumber(sectionElemList.length - 1, true),
    Infinity,
  ]);
  const isMobile = useIsMobile();
  const Y = useReadOnlyWindowScrollY();
  const width = useReadOnlyWindowSize();
  const navbarHeight = (isMobile) =>
    isMobile
      ? gnbMobileHeight + lnbMobileHeight
      : gnbTabletHeight + lnbTabletHeight;
  useEffect(() => {
    setOffsetTopList((prevState) => [
      ...sectionElemList.map((elem, i) => {
        if (!elem || !elem.current) return prevState[i];
        return elem.current.offsetTop - navbarHeight(isMobile);
      }),
      Infinity,
    ]);
  }, [Y, width]);
  return offsetTopList;
};

export const useActiveSectionIndex = (sectionElemList) => {
  const offsetTopList = useOffsetTopList(sectionElemList);
  const Y = useReadOnlyWindowScrollY();
  const width = useReadOnlyWindowSize();
  const [activeSection, updateActiveSection] = useState(0);
  useEffect(() => {
    const index = Math.max(
      offsetTopList.slice().findIndex((offsetTop) => Y < offsetTop) - 1,
      0
    );
    updateActiveSection(index);
  }, [Y, width]);
  return activeSection;
};

const getIndicatorShape = (activeNavItemRef, isMobile) => {
  let left = isMobile
    ? activeNavItemRef.current?.offsetLeft
    : activeNavItemRef.current?.offsetLeft - 5;
  if (isMobile) {
    left += (window.innerWidth * (1 - 0.82)) / 2;
  } else if (window.innerWidth >= 1230) {
    left += (window.innerWidth - 1230) / 2;
  }
  const width = isMobile
    ? activeNavItemRef.current?.clientWidth
    : activeNavItemRef.current?.clientWidth + 10;
  return { left: left, width: width };
};

export const useIndicator = (sectionElemList, navItemRefs) => {
  const [indicator, updateIndicator] = useState<Indicator>({
    left: 0,
    width: 0,
  });
  const activeSectionIndex = useActiveSectionIndex(sectionElemList);
  const Y = useReadOnlyWindowScrollY();
  const width = useReadOnlyWindowSize();
  const isMobile = useIsMobile();
  useEffect(() => {
    if (!!sectionElemList.filter((ref) => !ref || !ref.current).length) return;
    const activeNavItemRef = navItemRefs[activeSectionIndex];
    updateIndicator(getIndicatorShape(activeNavItemRef, isMobile));
  }, [Y, width, activeSectionIndex]);
  return indicator;
};

export const isSectionMovingAtom = atom<boolean>(false);
export const useMoveSection = (top) => {
  // 스크롤 다운 이벤트 발생시, 탭 이동인 경우 Gnb, Lnb 를 숨기지 않고 그대로 유지하기 위해 2초간 플래그 설정
  const [isMoving, setIsMoving] = useAtom(isSectionMovingAtom);
  return () => {
    if (typeof window !== "undefined") {
      setIsMoving(true);
      window.scroll({ top: top, behavior: "smooth" });
      setTimeout(() => {
        if (isMoving) {
          setIsMoving(false);
        }
      }, 2000);
    }
  };
};

export const useIsActive = (item) => {
  const [isActive, setIsActive] = useState(false);
  const isMatch = useIsTargetPage(item.href, item.isAbsoluteMatch);
  const router = useRouter();
  // blog 페이지는 url 만으로 isMatch 를 판단할 수 없으므로 별도의 로직을 실행
  const isBlogMatch = router.asPath.startsWith("/blog");
  // study club페이지는 url 만으로 isMatch 를 판단할 수 없으므로 별도의 로직을 실행
  const isStudyMatch = useIsTargetStudyClubPage(item.href);

  useEffect(() => {
    setIsActive(
      item.href?.startsWith("/blog")
        ? isBlogMatch
        : item.href?.includes("/kdc-study")
        ? isStudyMatch
        : isMatch
    );
  }, [isBlogMatch, isStudyMatch, isMatch]);
  return isActive;
};

export const useIsActiveBlogLnb = (item) => {
  const isBlogMatch = useIsTargetBlogCategory(item.href);
  return isBlogMatch;
};

export const useActiveItemOffsetRight = (isActive, itemRef) => {
  const [, setActiveLnbItemOffsetRight] = useAtom(activeLnbItemOffsetRightAtom);
  useEffect(() => {
    if (isActive) {
      setActiveLnbItemOffsetRight(
        itemRef.current?.offsetLeft + itemRef.current?.offsetWidth
      );
    }
  }, [isActive]);
};

export const useScrollToActiveItem = (wrapperRef) => {
  const [activeLnbItemOffsetRight] = useAtom(activeLnbItemOffsetRightAtom);
  useEffect(() => {
    if (
      wrapperRef.current &&
      activeLnbItemOffsetRight > wrapperRef.current.offsetWidth
    ) {
      wrapperRef.current.scrollTo({
        left: activeLnbItemOffsetRight,
        behavior: "smooth",
      });
    }
  }, [wrapperRef.current, activeLnbItemOffsetRight]);
};

export const useIsPWA = () => {
  const [isPWA, setIsPWA] = useState(null);
  const router = useRouter();
  useEffect(() => {
    setIsPWA(router.pathname.split("/")[1] === "app");
  }, []);
  return isPWA;
};

export const useRedirectNotPWA = () => {
  const isPWA = useIsPWA();
  const router = useRouter();
  useEffect(() => {
    if (isPWA !== null && !isPWA) {
      router.replace("/");
    }
  }, [isPWA]);
};
