import { useAtom } from "jotai";
import { useIsLoggedInV2 } from "@/businesslogics/_common/login";
import {
  useClickAnchor,
  useFixedPosition,
  useGnbConfig,
} from "@/businesslogics/_layout/gnb/v2";
import { sccAnchor } from "@/models/_layout/gnbV2.models";
import { isMyPageOpenAtom } from "@/stores/_layout/gnb";
import { GnbMyPageLogo } from "../../../atoms/_layout/gnb/v2/GnbMyPageLogo";
import { LineBanner } from "../../../organisms/_layout/LineBanner/LineBanner";
import { GnbMyPage } from "../molecules/GnbMyPage";
import { LoginButton } from "../molecules/LoginButton";
import { mobileGnbModelV3 } from "@/models/_layout/gnbV3.models";
import * as S from "./Gnb.mobile.style";
import GnbSpartaLogo from "src/components/atoms/Icons/SpartaLogo/GnbSpartaLogo";

export const GnbMobileV3 = () => {
  const [isMyPageOpen] = useAtom(isMyPageOpenAtom);
  const isFixed = useFixedPosition();
  const isLogin = useIsLoggedInV2();
  const onClickAnchor = useClickAnchor();
  const gnbConfig = useGnbConfig(mobileGnbModelV3);

  return (
    <S.Wrapper isTabOpened={isMyPageOpen} fixed={isFixed}>
      <S.TopMenuBar>
        <S.LeftBtnWrapper>
          {
            !isMyPageOpen && (
              // gnbConfig?.left ? (
              //   <S.BtnWrapper onClick={() => gnbConfig.left.onClick()}>
              //     {gnbConfig.left.text}
              //   </S.BtnWrapper>
              // ) : (
              <S.LogoWrapper onClick={() => onClickAnchor(sccAnchor)}>
                <GnbSpartaLogo showMainLogo={false} />
              </S.LogoWrapper>
            )
            // )
          }
        </S.LeftBtnWrapper>

        <S.GnbCenterContainer>
          {gnbConfig?.pageName && <S.PageName>{gnbConfig.pageName}</S.PageName>}
        </S.GnbCenterContainer>

        <S.GnbRightContainer>
          {isLogin ? (
            // gnbConfig?.right ? (
            //   <S.RightBtnWrapper onClick={() => gnbConfig.right.onClick()}>
            //     {gnbConfig.right.text}
            //   </S.RightBtnWrapper>
            // ) : (
            <GnbMyPageLogo />
          ) : (
            // )
            <LoginButton />
          )}
        </S.GnbRightContainer>
      </S.TopMenuBar>
      <LineBanner />
      <GnbMyPage /> {/* right SNB */}
    </S.Wrapper>
  );
};
