module.exports = {
  ON_SALE_URLS: {
    "/online/special/codingtest": "/product/33",
    "/online/web_basic": "/product/46",
    "/online/special/rtan_run": "/product/25",
    "/online/special/game_survivor": "/product/3",
    "/online/special/dev_strategy": "/product/31",
    "/online/special/dev_turnover": "/product/32",
    "/online/developer101": "/product/47",
    "/online/javascript_start": "/product/6",
    "/online/finance_automation": "/product/45",
    "/online/nocode": "/product/48",
    "/online/nocode_ai_app": "/product/28",
    "/online/special/1h_portfolio": "/product/29",
    "/online/data_v2": "/product/49",
    "/online/IT_101": "/product/27",
    "/online/special/developer_roadmap": "/product/26",
    "/online/algorithm": "/product/7",
    "/online/special/androidvsios": "/product/50",
    "/online/sql_2023": "/product/8",
    "/online/special/chatgpt_practical_use": "/product/9",
    "/online/spring_v2": "/product/10",
    "/online/web_chatgp": "/product/51",
    "/online/basicfigma": "/product/52",
    "/online/special/unemployed": "/product/11",
    "/online/workflow_automation": "/product/13",
    "/online/special/flutterflow_instagram": "/product/14",
    "/online/cloud_aws": "/product/15",
    "/online/special/code_review": "/product/16",
    "/online/3d_interactive_web": "/product/17",
    "/online/ai_analytics_blockchain": "/product/18",
    "/online/generative_ai": "/product/44",
    "/online/monthly/ai_report_automation": "/product/19",
    "/online/nocode_ai_automation": "/product/20",
    "/online/gpt_300_online": "/product/34",
    "/online/cicd": "/product/21",
    "/online/marketing_ga4": "/product/22",
    "/online/bio_app": "/product/23",
    "/online/3d_game": "/product/24",
    "/online/app": "/product/57",
    "/online/special/ai-surviving": "/product/60",
  },
};
