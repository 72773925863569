import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";
import Image from "next/legacy/image";
import { Device, device, SystemicBisection } from "./themes/device";
import { zIndexTooltip } from "./variables";

export const FixSizedBlock = styled.div<{ height?: string; width?: string }>`
  height: ${({ height }) => height};
  width: ${({ width }) => width};
`;

export const Gap = styled(FixSizedBlock)`
  white-space: initial;
  content: " ";
  opacity: 0;

  flex: ${({ height, width }) => height || width};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const Gap2 = ({ w, h }: { w?: string; h?: string }) => styled(
  FixSizedBlock
)`
  content: " ";
  opacity: 0;
  height: ${h};
  width: ${w};
  flex: ${h || w};
  flex-shrink: 0;
  flex-grow: 0;
`;

export const GapInline = styled(Gap)`
  display: inline-block;
`;

export const MobileOnly = styled.div`
  position: relative;
  @media ${device.desktop} {
    display: none;
  }
`;

export const DesktopOnly = styled.div`
  position: relative;
  @media ${device.lessThanDesktop} {
    display: none;
  }
`;

export const MobileImage = (props) => {
  return (
    <MobileOnly>
      <Image {...props} />
    </MobileOnly>
  );
};

export const DesktopImage = (props) => {
  return (
    <DesktopOnly>
      <Image {...props} />
    </DesktopOnly>
  );
};

export const DesktopOnlyBr = styled.br`
  display: none;

  ${Device.Desktop} {
    display: block;
  }
`;

export const MobileOnlyBr = styled.br`
  display: block;

  ${Device.Desktop} {
    display: none;
  }
`;

export const VerticalGap = styled.div<{ size: number }>`
  width: 100%;
  height: ${({ size }) => size}px;
`;

const getMediaQuery = ({ min, max }: { min?: number; max?: number }) => {
  if (min && max) {
    return `(min-width: ${min}px) and (max-width: ${max}px)`;
  } else if (min) {
    return `(min-width: ${min}px)`;
  } else if (max) {
    return `(max-width: ${max}px)`;
  }
  return "";
};
export const OnlyInRange = styled.div<{ min?: number; max?: number }>`
  display: none;
  @media ${getMediaQuery} {
    display: block;
  }
`;

export const WideOnly = styled.div`
  display: none;
  ${SystemicBisection.Wide} {
    display: block;
  }
`;

export const NarrowOnly = styled.div`
  display: none;

  ${SystemicBisection.Narrow} {
    display: block;
  }
`;

export const FloatingCtaSafeArea = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom));
  z-index: ${zIndexTooltip + 1};
`;
