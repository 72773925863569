import React, { useRef } from "react";
import * as S from "./LnbMolecule.style";
import { useLnb } from "@/businesslogics/_layout/gnb/v2";
import { MultiPageLnbItem } from "../../../atoms/_layout/lnb/MultiPageLnbItem";
import { useScrollToActiveItem } from "@/businesslogics/_common/lnb";
import { showLineBannerAtom } from "src/stores/showLineBannerAtom";
import { useAtom } from "jotai";
import { useRouter } from "next/router";
import { CodingLandLnb } from "./CodingLand/CodingLandLnb";
import { Sparthon4Lnb } from "./Sparthon4/Sparthon4Lnb";
import { useIsGnbMobile } from "@/businesslogics/_layout/window";
import { gnbMobileHeight } from "@/styles/variables";

const LnbOverMultiPages = () => {
  const router = useRouter();
  const lnbModel = useLnb();

  const wrapperRef = useRef(null);
  const [showLineBanner] = useAtom(showLineBannerAtom);
  useScrollToActiveItem(wrapperRef);

  const isMobile = useIsGnbMobile();
  return (
    <>
      {lnbModel && (
        <S.LnbWrapper
          ref={wrapperRef}
          showLineBanner={showLineBanner}
          top={gnbMobileHeight}
        >
          <S.LnbDiv>
            <S.LnbUl>
              {lnbModel.map((item) => (
                <MultiPageLnbItem
                  item={item}
                  key={"lnb_" + item.name}
                  isMobile={isMobile}
                />
              ))}
            </S.LnbUl>
          </S.LnbDiv>
        </S.LnbWrapper>
      )}
    </>
  );
};

export default LnbOverMultiPages;
