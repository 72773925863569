import styled from "@emotion/styled";
import { DisplayResolution } from "@/styles/themes/device";

const HomePage = ({ children }) => {
  return <Page>{children}</Page>;
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  ${DisplayResolution.TabletAndDesktop} {
  }
`;

export default HomePage;
