import * as S from "./LineBanner.style";
import { useIsMobile } from "@/businesslogics/_layout/window";
import { showLineBannerAtom } from "@/stores/showLineBannerAtom";
import { useAtom } from "jotai";
import { CloseLine, neutralDay } from "@teamsparta/design-system";

export const LineBanner = () => {
  const isMobile = useIsMobile();
  const [show, setShow] = useAtom(showLineBannerAtom);

  // GNB 띠 배너 추가 시 이 컴포넌트 수정해서 사용하시면 됩니다.
  // 애니메이션 적용하려면 conditional rendering 제거해주세요.

  return (
    <>
      {show && (
        <>
          <S.BannerWrapper show={show}>
            <S.Banner>
              <S.BannerTitle href={`${process.env.ONLINE_URL}/mypage/mycoupon`}>
                <S.BannerTitleContent>
                  <S.CollaborationPill>{/*  */}</S.CollaborationPill>
                  <S.BannerTitleText>{/*  */}</S.BannerTitleText>
                </S.BannerTitleContent>
              </S.BannerTitle>
              <S.CloseButtonWrapper onClick={() => setShow(false)}>
                <CloseLine
                  size={isMobile ? 16 : 20}
                  color={isMobile ? neutralDay.gray90 : neutralDay.gray80}
                />
              </S.CloseButtonWrapper>
            </S.Banner>
          </S.BannerWrapper>
        </>
      )}
    </>
  );
};
