import { mobileNavMainAnchor } from "@/models/_layout/gnbV3.models";
import * as S from "./Nav.mobile.style";
import { NavAnchorMobile } from "./atoms/NavAnchor.mobile";

export default function NavMobile() {
  return (
    <S.SafeArea>
      <S.Wrapper>
        <S.CategoriesContainer>
          {mobileNavMainAnchor.map((anchor) => {
            return (
              <NavAnchorMobile
                key={`nav-category-${anchor.key}`}
                anchor={anchor}
                isNewBadge={anchor.key === "aitc"}
              />
            );
          })}
        </S.CategoriesContainer>
      </S.Wrapper>
    </S.SafeArea>
  );
}
