export const NavigationLogoSvg = ({
  category,
  isActive,
}: {
  category: string;
  isActive: boolean;
}) => {
  switch (category) {
    case "home":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 24"
          fill={isActive ? "currentColor" : "none"}
        >
          <path
            d="M11.3074 4.38596L4.80736 9.21454C4.29942 9.59186 4 10.1873 4 10.82V20.5H6.69841H10V16.6857C10 15.305 11.1193 14.1857 12.5 14.1857C13.8807 14.1857 15 15.305 15 16.6857V20.5H17.3571H21V10.82C21 10.1873 20.7006 9.59186 20.1927 9.21454L13.6927 4.38597C12.9846 3.85995 12.0155 3.85995 11.3074 4.38596Z"
            stroke="currentColor"
            stroke-width="2"
          />
        </svg>
      );
    case "aitc":
      return isActive ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.5 4C5.84315 4 4.5 5.34315 4.5 7V17C4.5 18.6569 5.84315 20 7.5 20H17.5C19.1569 20 20.5 18.6569 20.5 17V7C20.5 5.34315 19.1569 4 17.5 4H7.5ZM15.5 9H9.5V15H15.5V9Z"
            fill="#3A3E41"
          />
          <path
            d="M9 2C9 1.44772 9.44772 1 10 1C10.5523 1 11 1.44772 11 2V5H9V2Z"
            fill="#3A3E41"
          />
          <path
            d="M16 22C16 22.5523 15.5523 23 15 23C14.4477 23 14 22.5523 14 22L14 19L16 19L16 22Z"
            fill="#3A3E41"
          />
          <path
            d="M22.5 8.5C23.0523 8.5 23.5 8.94772 23.5 9.5C23.5 10.0523 23.0523 10.5 22.5 10.5L19.5 10.5L19.5 8.5L22.5 8.5Z"
            fill="#3A3E41"
          />
          <path
            d="M2.5 15.5C1.94772 15.5 1.5 15.0523 1.5 14.5C1.5 13.9477 1.94772 13.5 2.5 13.5L5.5 13.5L5.5 15.5L2.5 15.5Z"
            fill="#3A3E41"
          />
          <path
            d="M14 2C14 1.44772 14.4477 1 15 1C15.5523 1 16 1.44772 16 2V5H14V2Z"
            fill="#3A3E41"
          />
          <path
            d="M11 22C11 22.5523 10.5523 23 10 23C9.44772 23 9 22.5523 9 22L9 19L11 19L11 22Z"
            fill="#3A3E41"
          />
          <path
            d="M22.5 13.5C23.0523 13.5 23.5 13.9477 23.5 14.5C23.5 15.0523 23.0523 15.5 22.5 15.5L19.5 15.5L19.5 13.5L22.5 13.5Z"
            fill="#3A3E41"
          />
          <path
            d="M2.5 10.5C1.94772 10.5 1.5 10.0523 1.5 9.5C1.5 8.94772 1.94772 8.5 2.5 8.5L5.5 8.5L5.5 10.5L2.5 10.5Z"
            fill="#3A3E41"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
          <rect
            x="5.5"
            y="5"
            width="14"
            height="14"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
          />
          <rect
            x="10"
            y="9.5"
            width="5"
            height="5"
            stroke="currentColor"
            stroke-width="2"
          />
          <path
            d="M9 2C9 1.44772 9.44772 1 10 1C10.5523 1 11 1.44772 11 2V5H9V2Z"
            fill="currentColor"
          />
          <path
            d="M16 22C16 22.5523 15.5523 23 15 23C14.4477 23 14 22.5523 14 22L14 19L16 19L16 22Z"
            fill="currentColor"
          />
          <path
            d="M22.5 8.5C23.0523 8.5 23.5 8.94772 23.5 9.5C23.5 10.0523 23.0523 10.5 22.5 10.5L19.5 10.5L19.5 8.5L22.5 8.5Z"
            fill="currentColor"
          />
          <path
            d="M2.5 15.5C1.94772 15.5 1.5 15.0523 1.5 14.5C1.5 13.9477 1.94772 13.5 2.5 13.5L5.5 13.5L5.5 15.5L2.5 15.5Z"
            fill="currentColor"
          />
          <path
            d="M14 2C14 1.44772 14.4477 1 15 1C15.5523 1 16 1.44772 16 2V5H14V2Z"
            fill="currentColor"
          />
          <path
            d="M11 22C11 22.5523 10.5523 23 10 23C9.44772 23 9 22.5523 9 22L9 19L11 19L11 22Z"
            fill="currentColor"
          />
          <path
            d="M22.5 13.5C23.0523 13.5 23.5 13.9477 23.5 14.5C23.5 15.0523 23.0523 15.5 22.5 15.5L19.5 15.5L19.5 13.5L22.5 13.5Z"
            fill="currentColor"
          />
          <path
            d="M2.5 10.5C1.94772 10.5 1.5 10.0523 1.5 9.5C1.5 8.94772 1.94772 8.5 2.5 8.5L5.5 8.5L5.5 10.5L2.5 10.5Z"
            fill="currentColor"
          />
        </svg>
      );
    case "study":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 25 24"
          fill={isActive ? "currentColor" : "none"}
        >
          <path
            d="M6.5 20H18.5"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
          />
          <rect
            x="4.5"
            y="4"
            width="16"
            height="12"
            rx="2"
            stroke="currentColor"
            stroke-width="2"
          />
          <path
            d="M11 8.40212V11.5979C11 11.9906 11.432 12.23 11.765 12.0219L14.3216 10.424C14.6349 10.2282 14.6349 9.77183 14.3216 9.576L11.765 7.97812C11.432 7.76998 11 8.00941 11 8.40212Z"
            fill={isActive ? "white" : "currentColor"}
          />
        </svg>
      );
    case "catalogv3":
      return isActive ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.5 23C18.5751 23 23.5 18.0751 23.5 12C23.5 5.92487 18.5751 1 12.5 1C6.42487 1 1.5 5.92487 1.5 12C1.5 18.0751 6.42487 23 12.5 23ZM10.2235 10.2201C10.3235 9.99995 10.5 9.82355 10.7201 9.7235L17.6713 6.56383C17.8396 6.48736 18.0126 6.66043 17.9362 6.82867L14.7765 13.7799C14.6764 14 14.5 14.1764 14.2799 14.2765L7.32867 17.4362C7.16043 17.5126 6.98736 17.3396 7.06384 17.1713L10.2235 10.2201ZM13.2069 12.7068C12.8163 13.0974 12.1832 13.0974 11.7927 12.7068C11.4021 12.3163 11.4021 11.6831 11.7927 11.2926C12.1832 10.9021 12.8163 10.9021 13.2069 11.2926C13.5974 11.6831 13.5974 12.3163 13.2069 12.7068Z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <circle
            cx="12.5"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.7201 9.7235C10.5 9.82355 10.3235 9.99995 10.2235 10.2201L7.06384 17.1713C6.98736 17.3396 7.16043 17.5126 7.32867 17.4362L14.2799 14.2765C14.5 14.1764 14.6764 14 14.7765 13.7799L17.9362 6.82867C18.0126 6.66043 17.8396 6.48736 17.6713 6.56383L10.7201 9.7235ZM11.7927 12.7068C12.1832 13.0974 12.8163 13.0974 13.2069 12.7068C13.5974 12.3163 13.5974 11.6831 13.2069 11.2926C12.8163 10.9021 12.1832 10.9021 11.7927 11.2926C11.4021 11.6831 11.4021 12.3163 11.7927 12.7068Z"
            fill="currentColor"
          />
        </svg>
      );
    case "community":
      return isActive ? (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.5 6C2.5 4.34315 3.84315 3 5.5 3H19.5C21.1569 3 22.5 4.34315 22.5 6V15C22.5 16.6569 21.1569 18 19.5 18H15.5542L13.348 21.53C13.1653 21.8224 12.8448 22 12.5 22C12.1552 22 11.8347 21.8224 11.652 21.53L9.44575 18H5.5C3.84315 18 2.5 16.6569 2.5 15V6ZM8.5 9.5C7.94772 9.5 7.5 9.94772 7.5 10.5C7.5 11.0523 7.94772 11.5 8.5 11.5C9.05228 11.5 9.5 11.0523 9.5 10.5C9.5 9.94772 9.05228 9.5 8.5 9.5ZM11.5 10.5C11.5 9.94772 11.9477 9.5 12.5 9.5C13.0523 9.5 13.5 9.94772 13.5 10.5C13.5 11.0523 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.0523 11.5 10.5ZM16.5 9.5C15.9477 9.5 15.5 9.94772 15.5 10.5C15.5 11.0523 15.9477 11.5 16.5 11.5C17.0523 11.5 17.5 11.0523 17.5 10.5C17.5 9.94772 17.0523 9.5 16.5 9.5Z"
            fill="#25282A"
          />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
          <path
            d="M8.5 9.5C7.94772 9.5 7.5 9.94772 7.5 10.5C7.5 11.0523 7.94772 11.5 8.5 11.5C9.05228 11.5 9.5 11.0523 9.5 10.5C9.5 9.94772 9.05228 9.5 8.5 9.5Z"
            fill="currentColor"
          />
          <path
            d="M11.5 10.5C11.5 9.94772 11.9477 9.5 12.5 9.5C13.0523 9.5 13.5 9.94772 13.5 10.5C13.5 11.0523 13.0523 11.5 12.5 11.5C11.9477 11.5 11.5 11.0523 11.5 10.5Z"
            fill="currentColor"
          />
          <path
            d="M16.5 9.5C15.9477 9.5 15.5 9.94772 15.5 10.5C15.5 11.0523 15.9477 11.5 16.5 11.5C17.0523 11.5 17.5 11.0523 17.5 10.5C17.5 9.94772 17.0523 9.5 16.5 9.5Z"
            fill="currentColor"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.5 3C3.84315 3 2.5 4.34315 2.5 6V15C2.5 16.6569 3.84315 18 5.5 18H9.44575L11.652 21.53C11.8347 21.8224 12.1552 22 12.5 22C12.8448 22 13.1653 21.8224 13.348 21.53L15.5542 18H19.5C21.1569 18 22.5 16.6569 22.5 15V6C22.5 4.34315 21.1569 3 19.5 3H5.5ZM4.5 6C4.5 5.44772 4.94772 5 5.5 5H19.5C20.0523 5 20.5 5.44772 20.5 6V15C20.5 15.5523 20.0523 16 19.5 16H15.5542C14.8647 16 14.2237 16.3552 13.8583 16.94L12.5 19.1132L11.1417 16.94C10.7763 16.3552 10.1353 16 9.44575 16H5.5C4.94772 16 4.5 15.5523 4.5 15V6Z"
            fill="currentColor"
          />
        </svg>
      );
    default:
      return <></>;
  }
};
