import * as S from "./courseCard.style";
import { ImageWrapperWithFallback } from "src/components/atoms/_common/ImageWrapperWithFallback/ImageWrapperWithFallback";
import { useRouter } from "next/router";
import { sendCPLog } from "@teamsparta/cross-platform-logger";
import { ProductCardType } from "src/models/home/homeV4.models";
import { applyEnvUrl } from "@/businesslogics/_common/utils/applyEnvUrl";

type ProductCardProps = {
  product: ProductCardType;
};

export const ProductCard = ({ product }: ProductCardProps) => {
  const router = useRouter();
  const onClickCard = () => {
    router.push(applyEnvUrl(product.url));
    sendCPLog("scc_mainPage_freeCard_click", {
      course_title: product.title,
      course_id: product.productId,
      is_free: true,
    });
  };

  const onClickCta = (buttonText) => {
    router.push(`${process.env.ACADEMIA_URL}/payment/${product.productId}`);
    sendCPLog("scc_mainPage_freeCard_apply_click", {
      button_text: buttonText,
      course_title: product.title,
      course_id: product.productId,
      is_free: true,
    });
  };

  return (
    <S.Container>
      <ImageWrapperWithFallback>
        <S.CardThumb src={product.thumbnailUrl} onClick={onClickCard} />
      </ImageWrapperWithFallback>
      <S.CardTextWrap>
        <S.CtaButton onClick={() => onClickCta("바로 수강신청")}>
          <ImageWrapperWithFallback>
            <img src={"/assets/icons/icon_thunder.svg"} alt={"icon_thunder"} />
          </ImageWrapperWithFallback>
          <S.CtaText>바로 수강신청</S.CtaText>
        </S.CtaButton>
        <S.CardTitleContainer onClick={onClickCard}>
          <S.Title>{product.title}</S.Title>
          <S.Desc>{product.description}</S.Desc>
        </S.CardTitleContainer>
        <S.Price>{product.price}</S.Price>
      </S.CardTextWrap>
    </S.Container>
  );
};
