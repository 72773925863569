import { UserMetaData } from "@/queries/gnb";

type Anchor = {
  key: string;
  name: string;
  href?: string;
  onClick?: Function;
  className?: string;
  needLogin?: boolean;
  isLoginNeededToNavigate?: boolean;
  brand?: string;
};

export const sccAnchor: Anchor = {
  key: "scc",
  name: "스파르타코딩클럽",
  href: "/",
  brand: "온라인",
};

export const pointAnchor: Anchor = {
  key: "point",
  name: "포인트",
  href: `${process.env.ONLINE_URL}/mypage/mypoint`,
  isLoginNeededToNavigate: true,
};

export const couponAnchor: Anchor = {
  key: "coupon",
  name: "쿠폰",
  href: `${process.env.ONLINE_URL}/mypage/mycoupon`,
  isLoginNeededToNavigate: true,
};

export const getLogEventName = (keyName) => {
  const gnbAnchorKeys = [
    "scc",
    "catalog",
    "nb",
    "chang",
    "hanghae",
    "blog",
    "community",
    "swcamp",
    "intellipick",
    "itacademy",
    "bisness",
    "faq",
    "ddingdong",
  ];
  const mypageAnchorKeys = [
    "classroom",
    "point",
    "coupon",
    "voucher",
    "certificate",
    "notice",
    "basicInfo",
    "logout",
    "rcmdsfree",
    "registrationStatus",
  ];
  if (gnbAnchorKeys.indexOf(keyName) !== -1) return "scc_gnb_click";
  if (mypageAnchorKeys.indexOf(keyName) !== -1) return "scc_myPage_click";
  if (keyName === "b2b") return "scc_b2b_click";
  if (["bizRecruit", "bizOutsourcing", "bizEducation"].indexOf(keyName) !== -1)
    return "scc_b2b_click";
  if (["sparthon4"].indexOf(keyName) !== -1)
    return "scc_spartonEventPage_lnb_click";
  return "scc_gnb_click";
};

export class userMetaDataModel {
  public classroom;
  public point;
  public coupon;
  public voucher;

  constructor(public queryResultData: UserMetaData) {
    this.classroom =
      !!this.queryResultData &&
      this.queryResultData.is_enrolled_exist &&
      "수강중";
    this.point = !!this.queryResultData
      ? `${this.queryResultData.point_amount.toLocaleString()}원`
      : undefined;
    this.coupon = !!this.queryResultData
      ? `${this.queryResultData?.active_coupon_count}장`
      : undefined;
    this.voucher = !!this.queryResultData
      ? `${this.queryResultData?.active_voucher_count}장`
      : undefined;
  }
}
