export const base64EncodeObject = (obj: any) => {
  const jsonEncoded = JSON.stringify(obj);
  const base64Encoded = Buffer.from(jsonEncoded).toString("base64");
  return encodeURIComponent(base64Encoded);
};

export const base64DecodeObject = (base64Encoded: string) => {
  if (!base64Encoded) {
    return null;
  }
  const decodeDate = decodeURIComponent(base64Encoded as string);
  const jsonData = Buffer.from(decodeDate, "base64").toString("utf-8");
  const parsedData = JSON.parse(jsonData);
  return parsedData;
};
