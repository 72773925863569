import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigateWithLoginV2 } from "src/businesslogics/_common/login";
import { useFixedPosition } from "src/businesslogics/_layout/gnb/v2";
import Tab from "src/components/molecules/_common/Tabs/Tab";
import {
  blogChannel,
  eventChannel,
  exhibitionChannel,
  mobileCommunityChannelModels,
} from "src/models/community/channel.models";
import {
  gnbMobileHeight,
  tabMobileHeight,
  zIndexLnb,
} from "src/styles/variables";

export const MobileChannelBar = () => {
  const router = useRouter();
  const isGnbFixed = useFixedPosition();
  const navigateWithLogin = useNavigateWithLoginV2();
  const [selectedChannelLabel, setSelectedChannelLabel] = useState("");

  // 클릭 핸들러 함수
  const handleClick = useCallback(
    (menu: { name: string; url: string; isLoginNeeded?: boolean }) => {
      const communityEndPoint = `/community/${menu.url}/all`;

      if (isDifferentChannel(menu.name)) {
        router.push(`/${menu.url}`);
        return;
      }

      if (menu.isLoginNeeded) {
        navigateWithLogin(communityEndPoint);
        return;
      }

      router.push(communityEndPoint);
    },
    [router, navigateWithLogin]
  );

  // 커뮤니티와 다른 페이지 여부 확인
  const isDifferentChannel = (channelName: string) => {
    const specialChannels = [
      blogChannel.name,
      eventChannel.name,
      exhibitionChannel.name,
    ];
    return specialChannels.includes(channelName);
  };

  // 탭 항목 생성
  const tabItems = useMemo(
    () =>
      mobileCommunityChannelModels.map((menu) => ({
        label: menu.name,
        onClick: () => handleClick(menu),
      })),
    [handleClick]
  );

  // 현재 경로에 해당되는 채널을 찾는 함수
  const findActiveChannel = () => {
    const pathSegments = router.asPath.split("/").filter(Boolean);

    // 커뮤니티 페이지 (자유게시판, 학습질문, 나의 활동)
    if (pathSegments[0] === "community") {
      return mobileCommunityChannelModels.find(
        (channel) => channel.url === pathSegments[1]
      );
    }

    // 블로그, 이벤트, 수강생 작품 페이지
    return mobileCommunityChannelModels.find(
      (channel) => channel.url === pathSegments[0]
    );
  };

  // 경로 변경 시 선택된 채널 업데이트
  useEffect(() => {
    const activeChannel = findActiveChannel();
    if (activeChannel) {
      setSelectedChannelLabel(activeChannel.name);
    }
  }, [router.asPath]);

  return (
    <StickyWrapper>
      <TabWrapper isGnbFixed={isGnbFixed}>
        <Tab
          tabItems={tabItems}
          initialSelectedTabLabel={selectedChannelLabel}
        />
      </TabWrapper>
    </StickyWrapper>
  );
};

const StickyWrapper = styled.div`
  position: sticky;
  z-index: ${zIndexLnb};
  height: ${tabMobileHeight}px;
  width: 100%;
`;
const TabWrapper = styled.div<{ isGnbFixed: boolean }>`
  position: fixed;
  top: ${gnbMobileHeight}px;
  max-height: ${tabMobileHeight}px;
  width: 100%;
  transition-duration: 300ms;
  transform: ${({ isGnbFixed }) =>
    isGnbFixed ? `translateY(0)` : `translateY(-50px)`};
`;
