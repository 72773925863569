import React, { useRef } from "react";
import * as S from "./LnbItem.style";
import {
  useClickAnchor,
  useIsTargetBlogCategory,
} from "@/businesslogics/_layout/gnb/v2";
import {
  useActiveItemOffsetRight,
  useIsActive,
} from "@/businesslogics/_common/lnb";
import { useRouter } from "next/router";
import { TabItem } from "src/components/molecules/_common/Tabs/Tab";

export const MultiPageLnbItem = ({
  item,
  isEventLnbActive,
  isMobile = false,
}: {
  item: any;
  isEventLnbActive?: any;
  isMobile?: boolean;
}) => {
  const onClickAnchor = useClickAnchor();
  const isBlogMatch = useIsTargetBlogCategory(item.href);
  const itemRef = useRef(null);
  useActiveItemOffsetRight(isBlogMatch, itemRef);
  const router = useRouter();
  const iscodingland =
    router.pathname === "/codingland" || router.pathname === "/sparthon4";

  return (
    <>
      {isMobile ? (
        <TabItem
          selected={isEventLnbActive ?? isBlogMatch}
          label={item.name}
          onClick={() => onClickAnchor(item)}
          type="useBadge"
        />
      ) : (
        <S.LnbItem
          iscodingland={iscodingland}
          active={isEventLnbActive ?? isBlogMatch}
          ref={itemRef}
        >
          <S.LnbItemText onClick={() => onClickAnchor(item)}>
            {item.name}
          </S.LnbItemText>
        </S.LnbItem>
      )}
    </>
  );
};
