import React, {
  ReactChild,
  ReactChildren,
  ReactNode,
  useEffect,
  useState,
} from "react";
import { useRouter } from "next/router";
import {
  BusinessInfoItems,
  CopyRightText,
  FooterCustomerService,
  FooterInfoFirstLineItems,
  FooterInfoSecondLineItems,
  FooterNavItemV2,
  FooterSNSItems,
  InfoDropDownIconImg,
  InfoDropDownText,
  NoFooterPages,
} from "@/models/_layout/footer.models";
import {
  updateCookies,
  useCleanKoreanCookie,
} from "@/businesslogics/_common/window/cookie";
import dynamic from "next/dynamic";
import { Gnb } from "../../organisms/_layout/gnb/Gnb";
import { Lnb } from "../../organisms/_layout/Lnb/Lnb";
import { useAtom } from "jotai";
import { isMyPageOpenAtom, isNavigatorOpenAtom } from "@/stores/_layout/gnb";
import { useIsNavMobile } from "@/businesslogics/_layout/window";
import * as layout from "./layout.style";
import {
  isLoginModalShowAtom,
  nextPageAfterLoginAtom,
} from "@/stores/_common/modal";
import {
  LoginProcess,
  useLockScroll,
  useUpdateIsLoggedIn,
} from "@teamsparta/auth-frontend";
import NbGuideGnb from "../../organisms/nbGuide/NbGuideGnb/NbGuideGnb";
import { useIsOutSourcing } from "../../atoms/b2b/lnb/lnbAtom";
import { FooterV2 } from "../../organisms/_layout/footer/FooterV2";
import { useLayoutPaddingTop } from "@/businesslogics/_layout/gnb/v2";
import * as CPL from "@teamsparta/cross-platform-logger";
import { Gap } from "@/styles/util";
import { StudyPaymentGnb } from "../../molecules/kdc/studyPaymentGnb/StudyPaymentGnb";
import { useRouterGuard } from "@/businesslogics/academia/useRouterGuard";
import { mobileCommunityChannelModels } from "src/models/community/channel.models";
import { MobileChannelBar } from "../channelBar/organisms/ChannelBar.mobile";
import { Navigation } from "../../organisms/_layout/navigation/Navigation";

interface LayoutProps {
  children: ReactChildren | ReactChild;
}

const ChannelScript = dynamic<ReactNode>(
  () => import("../../../../lib/channelTalk/script")
);

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter();
  let { f_uid, f_name, customGnb } = router.query;

  const isMobile = useIsNavMobile();
  const [isNavigatorOpen] = useAtom(isNavigatorOpenAtom);
  const [isMyPageOpen] = useAtom(isMyPageOpenAtom);
  const [isLoginModalShow, setIsLoginModalShow] = useAtom(isLoginModalShowAtom);
  const [gnb, setGnb] = useState(<Gnb />);
  const [isOutSourcing] = useIsOutSourcing();
  const [isChannelBarUrlMatched, setIsChannelBarUrlMatched] = useState(false);
  const [nextPageAfterLogin, setNextPageAfterLogin] = useAtom(
    nextPageAfterLoginAtom
  );
  const layoutPaddingTop = useLayoutPaddingTop();
  useLockScroll(isLoginModalShow);
  useUpdateIsLoggedIn(isLoginModalShow);

  useEffect(() => {
    const isChannelBarMatchingUrl = mobileCommunityChannelModels.some(
      (channel) => router.asPath.includes(`/${channel.url}`)
    );
    setIsChannelBarUrlMatched(isChannelBarMatchingUrl);
  }, [router.asPath]);

  useEffect(() => {
    updateCookies([
      { key: "f_uid", value: f_uid },
      { key: "f_name", value: f_name },
    ]);
    let { utm_source, utm_medium, utm_campaign, utm_content, utm_term } =
      router.query;
    updateCookies([
      { key: "utm_source", value: utm_source },
      { key: "utm_medium", value: utm_medium },
      { key: "utm_campaign", value: utm_campaign },
      { key: "utm_content", value: utm_content },
      { key: "utm_term", value: utm_term },
    ]);
  }, [f_uid, f_name]);

  useEffect(() => {
    if (customGnb === "nbGuide") {
      setGnb(<NbGuideGnb />);
    } else if (customGnb === "kdcStudy") {
      setGnb(<StudyPaymentGnb />);
    } else if (customGnb === "none") {
      setGnb(null);
    } else if (!customGnb) {
      setGnb(<Gnb />);
    }
  }, [customGnb]);

  useRouterGuard();
  useCleanKoreanCookie();
  return (
    <>
      {/*@ts-ignore*/}
      <ChannelScript />
      <layout.LoginModalWrapper>
        <LoginProcess
          isShow={isLoginModalShow}
          setIsShow={setIsLoginModalShow}
          CPLogger={CPL}
          nextPage={nextPageAfterLogin}
          initialTextProps={{
            subTitle: "대한민국을 코딩의 땅으로",
          }}
        />
      </layout.LoginModalWrapper>
      {/* <MarketingAgreementModal CPLogger={CPL} /> */}
      {/*<WelcomeModal CPLogger={CPL} />*/}
      {/* <UserProfileSurvey /> */}
      {gnb}
      {isMobile && isChannelBarUrlMatched && <MobileChannelBar />}
      <Lnb />
      {!(
        router.pathname === "/coding1010" ||
        router.pathname.includes("/codingpeople/share") ||
        router.pathname.includes("/aitc/apply") ||
        router.pathname.includes("/aitc/lt")
      ) && <Gap height={`${layoutPaddingTop}px;`} />}

      <layout.Contents
        unScrollable={isMobile && (isNavigatorOpen || isMyPageOpen)}
      >
        {children}
        {!(
          isOutSourcing ||
          NoFooterPages.some((page) => router.pathname.startsWith(page))
        ) && (
          <FooterV2
            footerCustomerService={FooterCustomerService}
            footerNavItems={FooterNavItemV2}
            footerSNSItems={FooterSNSItems}
            footerInfoFirstLineItems={FooterInfoFirstLineItems}
            footerInfoSecondLineItems={FooterInfoSecondLineItems}
            businessInfoItems={BusinessInfoItems}
            infoDropDownText={InfoDropDownText}
            infoDropDownIconImg={InfoDropDownIconImg}
            copyRightText={CopyRightText}
          />
        )}
        {/*<SmartLog />*/}
        <Navigation />
      </layout.Contents>
    </>
  );
};
