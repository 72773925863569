import styled from "@emotion/styled";
import { neutralDay } from "@teamsparta/design-system";
import { NavMobileHeight, zIndexNav } from "src/styles/variables";

export const SafeArea = styled.div`
  width: 100%;
  background-color: ${neutralDay.white};
  position: fixed;
  bottom: 0;
  padding-bottom: calc(env(safe-area-inset-bottom));
  z-index: ${zIndexNav};
`;

export const Wrapper = styled.div`
  border-top: 1px solid ${neutralDay.gray10};
  height: ${NavMobileHeight}px;
  padding-top: 7px;
  padding-bottom: 7px;
`;

export const CategoriesContainer = styled.ul`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
