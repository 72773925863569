import styled from "@emotion/styled";

export const GnbTopLogo = ({ category }) => {
  switch (category) {
    case "hanghae":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clip-path="url(#clip0_1240_29280)">
            <rect width="12" height="12" fill="#F50000" />
            <path
              d="M9.08813 3.59306V8.31698L9.88679 7.865V3.59306H9.08813Z"
              fill="white"
            />
            <path
              d="M10.3147 3.59526V8.27091L11.0519 7.86281V3.59526H10.3147Z"
              fill="white"
            />
            <path
              d="M10.308 5.306H9.88452V6.07174H10.308V5.306Z"
              fill="white"
            />
            <path
              d="M6.30811 4.20303H8.56146V4.92928L7.41613 4.74937L6.30811 4.92928V4.20303Z"
              fill="white"
            />
            <path
              d="M7.25814 4.02753L7.81325 3.71378V4.20306H6.97729L7.25814 4.02753Z"
              fill="white"
            />
            <path
              d="M7.43612 4.745C6.71645 4.745 6.13501 5.50196 6.13501 6.43665C6.13501 7.37135 6.71864 8.12831 7.43612 8.12831C8.15359 8.12831 8.73723 7.37135 8.73723 6.43665C8.73723 5.50196 8.15359 4.745 7.43612 4.745ZM7.43612 7.30333C7.06751 7.30333 6.76911 6.91497 6.76911 6.43665C6.76911 5.95834 7.06751 5.56998 7.43612 5.56998C7.80473 5.56998 8.10313 5.95834 8.10313 6.43665C8.10313 6.91497 7.80253 7.30333 7.43612 7.30333Z"
              fill="white"
            />
            <path
              d="M5.21768 6.23698L6.03389 5.46685V5.12895H4.98291L5.21768 6.23698Z"
              fill="white"
            />
            <path
              d="M5.52046 5.94516L5.22206 6.23697H4.64062V3.58649H5.5051L5.52046 5.94516Z"
              fill="white"
            />
            <path
              d="M2.32591 4.19428C1.57333 4.19428 0.95459 4.58922 0.95459 5.21674C0.95459 5.90569 1.56455 6.31818 2.31713 6.31818C3.06971 6.31818 3.69723 5.84645 3.69723 5.21674C3.69723 4.58703 3.07849 4.19428 2.32591 4.19428ZM2.31713 5.87717C1.98143 5.87717 1.70936 5.59193 1.70936 5.23868C1.70936 4.88543 1.98143 4.60019 2.31713 4.60019C2.65283 4.60019 2.9249 4.88543 2.9249 5.23868C2.9249 5.59193 2.65283 5.87717 2.31713 5.87717Z"
              fill="white"
            />
            <path
              d="M2.22714 3.58649L2.78225 3.27273V3.76201H1.94629L2.22714 3.58649Z"
              fill="white"
            />
            <path
              d="M1.00513 3.7598H3.58101V4.37415L2.27332 4.24031L1.00513 4.37415V3.7598Z"
              fill="white"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.23765 7.2704C5.23765 8.02655 4.45472 8.63953 3.48894 8.63953C2.52316 8.63953 1.74023 8.02655 1.74023 7.2704C1.74023 6.51425 2.52316 5.90128 3.48894 5.90128C4.45472 5.90128 5.23765 6.51425 5.23765 7.2704ZM4.31164 7.27042C4.31164 7.67394 3.94326 8.00106 3.48885 8.00106C3.03443 8.00106 2.66606 7.67394 2.66606 7.27042C2.66606 6.8669 3.03443 6.53978 3.48885 6.53978C3.94326 6.53978 4.31164 6.8669 4.31164 7.27042Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1240_29280">
              <rect width="12" height="12" rx="2" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
    case "intellipick":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <rect width="12" height="12" rx="1.37143" fill="#6E50FF" />
          <path
            d="M4.23194 6.55457C3.8616 6.10841 3.72312 5.94172 3.4145 5.57201C2.80823 5.75342 2.55194 5.82924 1.99048 6.00345C2.47988 7.03864 2.6005 7.26235 3.18265 8.51405H5.1581C5.57168 6.55457 8.22954 3.7695 10.7618 3.48627C7.34693 3.48627 5.45105 4.94601 4.23225 6.55457H4.23194Z"
            fill="#FFCB40"
          />
        </svg>
      );
    default:
      return (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_857_121114)">
            <path
              d="M8.92577 0.151863L1.35899 1.81076L0 6.26281L3.07423 9.80274L5.40228 9.29185L6.07686 11.8481L9.14794 8.55847L10.641 8.1456L12 3.69179L8.92577 0.151863Z"
              fill="#E8344E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.75894 3.79747V4.21457H9.15695V4.63168H9.55497V5.04878H9.95263V5.46589H9.55497V5.88299H9.15695V6.3001H8.75929V6.7172H7.96362V6.3001H8.36128V5.88299H8.75894V5.46589H9.15695V5.04878H8.75929V4.63133H8.36163V4.21422H7.96397V3.79747H8.75894ZM7.45511 3.87114H6.76123V4.27379H6.36918V4.68314H5.9982V5.08543H5.60615V5.49513H5.23482V5.89743H4.84277V6.30713H4.47144V6.71648H5.16567V6.30713H5.53666V5.90448H5.9287V5.49513H6.29969V5.09284H6.69208V4.68314H7.06307V4.28084H7.45511V3.87114ZM3.16829 6.33632V6.75342V6.75413H3.96326V6.33702H3.5656V5.91992H3.16794V5.50281H2.77028V5.08571H3.16829V4.66861H3.56595V4.2515H3.96361V3.8344H3.16794V4.2508H2.77028V4.6679H2.37227V5.085H1.97461V5.50211H2.37227V5.91921H2.77028V6.33632H3.16829Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_857_121114">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
  }
};

const Logo = styled.img`
  width: 12px;
  height: 12px;
`;
