import { SystemicDevice } from "@/styles/themes/device";
import styled from "@emotion/styled";
import { maxWidth } from "@/styles/themes/_variables";
import {
  gnbTabletHeight,
  gnbTopMenuHeight,
  lineBannerTabletHeight,
  zIndexLnb,
} from "@/styles/variables";
import { neutralDay } from "@teamsparta/design-system";

export const LnbWrapper = styled.div<{
  showLineBanner;
  top?;
}>`
  position: relative;
  top: ${({ top }) => top && top}px;
  background-color: ${neutralDay.white};
  display: flex;
  justify-content: start;
  align-items: start;

  transition: all 0.4s;
  width: 100vw;
  z-index: ${zIndexLnb - 1};
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
  }

  ${SystemicDevice.TabletAndDesktop} {
    background: rgba(246, 249, 250, 0.9);
    box-shadow: inset 0px -1px 0px #eef3f6;
    backdrop-filter: blur(25px);

    top: ${({ showLineBanner }) =>
      showLineBanner
        ? `${lineBannerTabletHeight + gnbTabletHeight + gnbTopMenuHeight}px`
        : `${gnbTabletHeight + gnbTopMenuHeight}px`};
  }
`;

export const LnbDiv = styled.div`
  ${SystemicDevice.TabletAndDesktop} {
    white-space: nowrap;
    max-width: ${maxWidth}px;
    margin: auto;
    flex-direction: column;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: fit-content;
  }
`;

export const LnbUl = styled.ul`
  display: flex;
  padding: 16px;
  gap: 8px;

  ${SystemicDevice.TabletAndDesktop} {
    width: 400px;
    padding: 12px;
    justify-content: inherit;
    gap: 50px;
  }
`;
