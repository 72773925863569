import instance from "axios";
import { useMutation, useQuery } from "react-query";
import { GovFunnelStatus, GovRoundDto } from "@/businesslogics/nb/nb.dto";
import {
  CallCenterDto,
  KdcTelephoneManageDto,
} from "src/businesslogics/kdc/kdc.dto";

const getGovRound = async (url: string) => {
  try {
    const response = await instance.get<GovRoundDto>(
      `${process.env.API_URL}/round/gov/${url}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useGovRound = (url: string) => {
  return useQuery<GovRoundDto | undefined, Error>(
    ["govRound", url],
    () => getGovRound(url),
    {
      enabled: !!url,
    },
  );
};

const getGovEnrolled = async () => {
  try {
    const response = await instance.get<number>(
      `${process.env.API_URL}/enrolled/gov`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useGovEnrolled = () => {
  return useQuery<number | undefined, Error>([], () => getGovEnrolled());
};

export const useApplyTelephoneManage = () => {
  return useMutation((data: KdcTelephoneManageDto) => {
    return instance.post(`${process.env.API_URL}/kdc/telephone_manage`, data);
  });
};

export const useApplyCallCenter = () => {
  return useMutation((data: CallCenterDto) => {
    return instance.post(
      `${process.env.ONLINE_API_URL}/v2/call_center/request_log`,
      data,
    );
  });
};

const getHasCardByUserId = async (userId: string) => {
  try {
    const response = await instance.get<boolean>(
      `${process.env.API_URL}/kdc/check_no_card/${userId}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useHasCardByUserId = (userId: string | undefined) => {
  return useQuery<boolean | undefined, Error>(
    ["hasCardByUserId", userId],
    () => getHasCardByUserId(userId!),
    {
      enabled: !!userId,
    },
  );
};
export const getGovFunnelStatus = async (userId: string) => {
  try {
    const response = await instance.get<GovFunnelStatus>(
      `${process.env.API_URL}/kdc/gov_funnel_status/${userId}`,
    );
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export const useGovFunnelStatus = (userId: string | undefined) => {
  return useQuery(
    ["govFunnelStatus", userId],
    () => getGovFunnelStatus(userId!),
    {
      enabled: !!userId,
    },
  );
};
