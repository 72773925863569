import styled from "@emotion/styled";
import { neutralDay, scc } from "@teamsparta/design-system";
import { useRouter } from "next/router";
import {
  useClickAnchor,
  useIsTargetPage,
} from "src/businesslogics/_layout/gnb/v2";
import { NavigationLogoSvg } from "src/components/atoms/_layout/navigation/Nav.style.svg";
import {
  aiClub,
  blogAnchor,
  communityAnchor,
  eventAnchor,
  exhibitionAnchor,
} from "@/models/_layout/gnbV3.models";
import { Anchor } from "@/components/templates/gnbV3/types/gnbV3";

export const NavAnchorMobile = ({
  anchor,
  isNewBadge = false,
}: {
  anchor: Anchor;
  isNewBadge?: boolean;
}) => {
  const router = useRouter();
  const { pathname } = router;
  const onClickAnchor = useClickAnchor();
  // TODO: aitc, 스터디 클럽 경로 처리 방식 수정 필요
  const isMatch = useIsTargetPage(anchor.href, true);
  const segment = pathname.split("/").filter(Boolean)[0];

  // 커뮤니티
  const isCommunityNavTab = [
    blogAnchor.key,
    communityAnchor.key,
    eventAnchor.key,
    exhibitionAnchor.key,
  ].includes(segment);
  // aitc
  const isAitcNavTab = [aiClub.key, `${aiClub.key}/course`].includes(segment);

  const isActive = (() => {
    if (isCommunityNavTab) return anchor.key === communityAnchor.key;
    if (isAitcNavTab) return anchor.key === aiClub.key;
    return isMatch;
  })();

  return (
    <NavAnchor isActive={isActive} onClick={() => onClickAnchor(anchor)}>
      <NavSvgWrapper>
        {isNewBadge && <NewBadge />}
        <NavigationLogoSvg category={anchor.key} isActive={isActive} />
      </NavSvgWrapper>
      <NameText isActive={isActive}>{anchor.name}</NameText>
    </NavAnchor>
  );
};

export const NavAnchor = styled.button<{ isActive: boolean }>`
  display: flex;
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.gray60};
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 4px;
`;

export const NavSvgWrapper = styled.div`
  position: relative;
  width: 24px;
  height: 24px;
`;

export const NameText = styled.div<{ isActive: boolean }>`
  text-align: center;
  font-family: Pretendard;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray100 : neutralDay.gray60};
`;

export const NewBadge = styled.div`
  display: flex;
  position: absolute;
  top: -4px;
  right: -5px;
  width: 8px;
  height: 8px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: ${scc.red100};
`;
