import { useRef } from "react";
import * as S from "./recommendCards.style";
import { ProductCard } from "../CourseCard/ProductCard";
import { Scrollbar } from "src/components/atoms/Scrollbar/Scrollbar";
import { ProductCardType } from "src/models/home/homeV4.models";

interface RecommendCardsProps {
  title: string;
  description: string;
  cards: ProductCardType[];
  columnCount?: number;
}

export const RecommendCards = ({
  title,
  description,
  cards,
  columnCount = 4,
}: RecommendCardsProps) => {
  const cardContainerRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container>
      <S.TextContainer>
        <S.Title>{title}</S.Title>
        <S.SubTitle>{description}</S.SubTitle>
      </S.TextContainer>

      <S.MobileCardWrap>
        <S.CardContainer ref={cardContainerRef}>
          {cards.map((card, index) => (
            <ProductCard key={`card-${card.title}-${index}`} product={card} />
          ))}
        </S.CardContainer>
        <Scrollbar
          containerRef={cardContainerRef}
          scrollBarWidth={70}
          scrollBarPointerWidth={41}
        />
      </S.MobileCardWrap>

      <S.DesktopCardWrap columnCount={columnCount}>
        {cards.map((card, index) => (
          <ProductCard key={`card-${card.title}-${index}`} product={card} />
        ))}
      </S.DesktopCardWrap>
    </S.Container>
  );
};
