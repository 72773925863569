import styled from "@emotion/styled";
import { useClickAnchor } from "@/businesslogics/_layout/gnb/v2";
import { neutralDay, wBody1, wCaption1 } from "@teamsparta/design-system";
import { SystemicDevice } from "@/styles/themes/device";
import { useIsActive } from "@/businesslogics/_common/lnb";
import { sccAnchor } from "@/models/_layout/gnbV2.models";
import { GnbTopLogo } from "./GnbTop.logo";

export const GnbAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor();
  const isMatch = useIsActive(anchor);

  return (
    <GnbAnchor
      isLighter={!isCategory}
      isActive={isMatch}
      onClick={() => onClickAnchor(anchor)}
    >
      {anchor.name}
      {anchor.tag && <Tag>{anchor.tag}</Tag>}
    </GnbAnchor>
  );
};

export const GnbTopAnchorDesktop = ({ anchor, isCategory = false }) => {
  const onClickAnchor = useClickAnchor();

  return (
    <GnbTopAnchor
      isLighter={!isCategory}
      isActive={anchor.key === sccAnchor.key}
      onClick={() => onClickAnchor(anchor)}
    >
      {isCategory && <GnbTopLogo category={anchor.key} />}
      <div>{anchor.name}</div>
    </GnbTopAnchor>
  );
};

const GnbAnchor = styled.div<{ isLighter?; isActive }>`
  ${wBody1};
  padding: 9px 10px 8px;
  color: ${({ isLighter, isActive }) =>
    isActive ? "#E8344E" : isLighter ? "#81898F" : "#141617"};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;

  :hover {
    display: inline-flex;
    padding: 9px 10px 8px;
    border-radius: 6px;
    background: ${neutralDay.gray5};
  }
`;

const GnbTopAnchor = styled.div<{ isLighter?; isActive }>`
  ${wCaption1};
  display: flex;
  gap: 4px;
  align-items: center;
  color: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.gray60};
  background-color: ${({ isActive }) =>
    isActive ? neutralDay.white : undefined};
  padding: 5px 12px;
`;

export const Tag = styled.div`
    display: flex;
    padding: 1px 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: ${neutralDay.gray10};
    color: ${neutralDay.gray70};
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 15px */

    ${SystemicDevice.TabletAndDesktop} {
        height: 18px;
    }
`;
