import {
  kdcCoursesOnSale,
  NbCourse,
  NbCourseNotShowing,
} from "src/models/kdc/courses.model";

export const getTitleFlattened = (title: string) =>
  title?.replaceAll(" ", "").replace("[왕초보]", "");

export const getKdcCourseById = (
  kdcCourseId: string,
): NbCourse | NbCourseNotShowing | undefined => {
  return Object.values(kdcCoursesOnSale).find(
    (course) => course.kdcCourseId === kdcCourseId,
  );
};

export const getKdcCoursesByIds = (kdcCourseIds: string[]) => {
  return kdcCourseIds
    .map((kdcCourseId) => getKdcCourseById(kdcCourseId))
    .filter((course) => course !== undefined);
};
