import styled from "@emotion/styled";
import {
  mBody1,
  mBody2,
  mBody3,
  neutralDark,
  neutralDay,
} from "@teamsparta/design-system";
import { SystemicDevice } from "src/styles/themes/device";
import { tabMobileHeight, zIndexLnb } from "src/styles/variables";

export const Container = styled.ul<{ fixedGnbHeight?: number }>`
  z-index: ${zIndexLnb - 1};
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: ${tabMobileHeight}px;
  position: sticky;
  background-color: ${neutralDay.white};
  display: flex;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  ${SystemicDevice.TabletAndDesktop} {
    display: none;
  }
  top: ${({ fixedGnbHeight }) => fixedGnbHeight && `${fixedGnbHeight}px`};
`;

export const BadgeTabContainer = styled(Container)`
  padding: 16px;
  gap: 8px;
`;

export const BadgeTabItemContainer = styled.button<{ isActive: boolean }>`
  display: flex;
  flex-shrink: 0;
  ${mBody3};
  padding: 6px 12px;
  justify-content: center;
  align-items: flex-start;
  border-radius: 99px;
  border: 1px solid ${neutralDark.gray100};
  color: ${({ isActive }) =>
    isActive ? neutralDay.white : neutralDay.gray100};
  background: ${({ isActive }) =>
    isActive ? neutralDay.gray90 : neutralDay.white};
`;

export const UnderlineTabContainer = styled(Container)`
  height: 48px;
  border-bottom: 1px solid ${neutralDay.gray10};
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
  justify-content: space-between;
`;

export const UnderlineTab = styled.button<{ isActive: boolean }>`
  padding: 13px 12px;
  ${mBody1};
  max-width: 100%;
  min-width: fit-content;
  width: 100%;
  text-align: center;
  border-bottom: ${({ isActive }) =>
    isActive ? `2px solid ${neutralDay.gray90}` : "none"};
  height: 48px;
  color: ${({ isActive }) =>
    isActive
      ? `${neutralDay.gray90} !important`
      : `${neutralDay.gray60} !important`};

  ${({ isActive }) => (isActive ? mBody1 : mBody2)};

  @media (hover: hover) {
    &:hover {
      background-color: ${neutralDay.gray0};
      transition: 0.2s;
    }
  }
`;
