import styled from "@emotion/styled";

export default function GnbSpartaLogo({
  showMainLogo = true,
}: {
  showMainLogo?: boolean;
}) {
  return (
    <LogoWrapper>
      <svg
        width="47"
        height="25"
        viewBox="0 0 47 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="sparta">
          <path
            id="Vector"
            d="M38.3032 21.4933V21.9294H38.7119V22.3646H39.1207V22.8008H39.5294V23.2369H39.1207V23.6734H38.7119V24.1095H38.3032V24.5456H37.4858V24.1103H37.8945V23.6742H38.3032V23.2377H38.7119V22.8016H38.3032V22.3646H37.8945V21.9294H37.4858V21.4933H38.3032Z"
            fill="#E8344E"
          />
          <path
            id="Vector_2"
            d="M36.9944 21.5316H36.2824V21.9523H35.8802V22.3809H35.4989V22.8019H35.0967V23.2301H34.7158V23.6511H34.3136V24.0797H33.9327V24.5078H34.6447V24.0797H35.0256V23.6586H35.4282V23.2301H35.8087V22.8094H36.2113V22.3809H36.5918V21.9598H36.9944V21.5316Z"
            fill="#E8344E"
          />
          <path
            id="Vector_3"
            d="M29.5508 24.5465V24.1104H29.142V23.6742H28.7333V23.2377H28.3246V22.8016H28.7333V22.3646H29.142V21.9294H29.5508V21.4933H30.3682V21.9294H29.9595V22.3646H29.5508V22.8008H29.142V23.2369H29.5508V23.6734H29.9595V24.1095H30.3682V24.5456L29.5508 24.5465Z"
            fill="#E8344E"
          />
          <path
            id="Subtract"
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.7646 7.72344L39.9004 0.307861L46.1538 7.6481L43.3892 16.883L38.4727 17.8645L32.2254 24.6854L30.8529 19.3855L6.25387 24.2953L0 16.955L2.7646 7.72344ZM5.00349 14.8958V14.0386H4.22692V13.1814H5.86631L5.90718 14.0386H8.06524V12.3226H5.00349V11.4671H4.26779V9.75114H5.00349V8.89608H9.01429V9.75323H9.74999V10.6104H8.11101V9.75323H5.90881V11.4692H9.01511V12.3243H9.75081V14.0403H9.01511V14.8974L5.00349 14.8958ZM17.5001 14.8958V10.6104H18.0756V9.75324H18.9126V8.89608H21.4238V9.75324H22.2605V10.6104H22.8364V14.8958H21.4238V13.1798H18.9126V14.8958H17.5001ZM18.9126 12.3243H21.4238V10.6083H20.5868V9.75324H19.7497V10.6104H18.9126V12.3243ZM36.8205 10.6104V14.8958H38.2331V13.1798H40.7443V14.8958H42.1568V10.6104H41.5813V9.75324H40.7443V8.89608H38.2335V9.75324H37.3968V10.6104H36.8205ZM40.7443 12.3243H38.2331V10.6104H39.0726V9.75324H39.9072V10.6083H40.7443V12.3243ZM32.5806 14.8958V9.75324H30.8178V8.89608H35.8402V9.75324H34.1235V14.8958H32.5806ZM15.7769 8.89608V9.75324H16.2853V11.4537H15.7769V12.3109H12.5537V14.8958H11.2294V9.76077H11.8258V8.89608H15.7769ZM12.5537 11.4537H14.984L14.9819 9.75324H12.5537V11.4537ZM29.0935 14.0386V13.1814H28.2565V12.1904H29.0441V11.4475H29.669V9.75324H29.0935V8.89608H25.0635V9.76077H24.4615V14.8958H25.8311V12.3247H26.7953V13.2827H27.5052V14.0319H28.263L28.2577 14.8958H29.9314V14.0386H29.0935ZM25.8311 11.468V9.75199H28.2577V10.6096L28.263 11.4667L25.8311 11.468Z"
            fill="#E8344E"
          />
        </g>
      </svg>
      {showMainLogo && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="31"
          height="17"
          viewBox="0 0 31 17"
          fill="none"
        >
          <path
            d="M14.9713 0.385025H14.0952V1.2832H14.9713V0.385025Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 0.385025H15.0896V1.2832H15.9658V0.385025Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 0.385025H18.081V1.2832H18.9571V0.385025Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 0.385025H17.077V1.2832H17.963V0.385025Z"
            fill="#E8344E"
          />
          <path
            d="M4.28067 2.41055H3.40454V3.30873H4.28067V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 5.4442H0.402466V6.34237H1.28846V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 5.4442H1.40631V6.34237H2.28245V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 4.43646H0.402466V5.32465H1.28846V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 3.4285H0.402466V4.31669H1.28846V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 6.46214H1.40631V7.35033H2.28245V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M4.28067 6.46214H3.40454V7.35033H4.28067V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 2.41055H1.40631V3.30873H2.28245V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 4.43646H1.40631V5.32465H2.28245V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 3.4285H1.40631V4.31669H2.28245V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M3.2871 2.41055H2.40112V3.30873H3.2871V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M3.2871 6.46214H2.40112V7.35033H3.2871V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M9.07464 2.41055H8.19852V3.30873H9.07464V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M9.07464 4.43646H8.19852V5.32465H9.07464V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M9.07464 3.4285H8.19852V4.31669H9.07464V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M9.07464 5.4442H8.19852V6.34237H9.07464V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M10.0692 5.4442H9.19305V6.34237H10.0692V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M10.0692 3.4285H9.19305V4.31669H10.0692V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M10.0692 4.43646H9.19305V5.32465H10.0692V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M8.08065 6.46214H7.19467V7.35033H8.08065V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 6.46214H6.20099V7.35033H7.07713V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 3.4285H5.19659V4.31669H6.08257V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 4.43646H5.19659V5.32465H6.08257V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M9.07464 6.46214H8.19852V7.35033H9.07464V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 5.4442H5.19659V6.34237H6.08257V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 5.4442H6.20099V6.34237H7.07713V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M8.08065 2.41055H7.19467V3.30873H8.08065V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 4.43646H6.20099V5.32465H7.07713V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 3.4285H6.20099V4.31669H7.07713V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 2.41055H6.20099V3.30873H7.07713V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 6.46214H15.0896V7.35033H15.9658V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 4.43646H14.0952V5.32465H14.9713V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 3.4285H14.0952V4.31669H14.9713V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 1.40302H14.0952V2.29121H14.9713V1.40302Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 2.41055H14.0952V3.30873H14.9713V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 1.40302H15.0896V2.29121H15.9658V1.40302Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 2.41055H15.0896V3.30873H15.9658V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 4.43646H15.0896V5.32465H15.9658V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 3.4285H15.0896V4.31669H15.9658V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M15.9658 5.4442H15.0896V6.34237H15.9658V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M11.978 5.4442H11.092V6.34237H11.978V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M12.972 6.46214H12.0959V7.35033H12.972V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M12.972 3.4285H12.0959V4.31669H12.972V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M12.972 4.43646H12.0959V5.32465H12.972V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M11.978 3.4285H11.092V4.31669H11.978V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M12.972 5.4442H12.0959V6.34237H12.972V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 6.46214H14.0952V7.35033H14.9713V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M13.9772 6.46214H13.0912V7.35033H13.9772V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M12.972 2.41055H12.0959V3.30873H12.972V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M13.9772 2.41055H13.0912V3.30873H13.9772V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M11.978 4.43646H11.092V5.32465H11.978V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M14.9713 5.4442H14.0952V6.34237H14.9713V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 6.46214H17.077V7.35033H17.963V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 3.4285H18.081V4.31669H18.9571V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 5.4442H18.081V6.34237H18.9571V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 2.41055H18.081V3.30873H18.9571V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 4.43646H18.081V5.32465H18.9571V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 5.4442H17.077V6.34237H17.963V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 4.43646H17.077V5.32465H17.963V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 3.4285H17.077V4.31669H17.963V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M18.9571 6.46214H18.081V7.35033H18.9571V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M17.963 2.41055H17.077V3.30873H17.963V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M23.8604 3.4285H22.9843V4.31669H23.8604V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M20.8671 6.46214H19.9811V7.35033H20.8671V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M23.8604 2.41055H22.9843V3.30873H23.8604V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M23.8604 5.4442H22.9843V6.34237H23.8604V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M23.8604 6.46214H22.9843V7.35033H23.8604V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M24.8537 6.46214H23.9776V7.35033H24.8537V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M24.8537 4.43646H23.9776V5.32465H24.8537V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M22.8652 2.41055H21.9792V3.30873H22.8652V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M24.8537 3.4285H23.9776V4.31669H24.8537V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M24.8537 5.4442H23.9776V6.34237H24.8537V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M23.8604 4.43646H22.9843V5.32465H23.8604V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M21.8612 6.46214H20.985V7.35033H21.8612V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M21.8612 2.41055H20.985V3.30873H21.8612V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M20.8671 3.4285H19.9811V4.31669H20.8671V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M20.8671 4.43646H19.9811V5.32465H20.8671V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M20.8671 5.4442H19.9811V6.34237H20.8671V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M20.8671 2.41055H19.9811V3.30873H20.8671V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M21.8612 3.4285H20.985V4.31669H21.8612V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M21.8612 5.4442H20.985V6.34237H21.8612V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M21.8612 4.43646H20.985V5.32465H21.8612V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 6.46214H28.9V7.35033H29.7761V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M28.7809 8.49762H27.8949V9.39579H28.7809V8.49762Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 2.41055H29.8933V3.30873H30.7694V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 7.47017H28.9V8.36834H29.7761V7.47017Z"
            fill="#E8344E"
          />
          <path
            d="M28.7809 6.46214H27.8949V7.35033H28.7809V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M28.7809 2.41055H27.8949V3.30873H28.7809V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 8.49762H28.9V9.39579H29.7761V8.49762Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 3.4285H29.8933V4.31669H30.7694V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 4.43646H29.8933V5.32465H30.7694V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 2.41055H28.9V3.30873H29.7761V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 7.47017H29.8933V8.36834H30.7694V7.47017Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 5.4442H29.8933V6.34237H30.7694V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 2.41055H26.9008V3.30873H27.7769V2.41055Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 3.4285H28.9V4.31669H29.7761V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M30.7694 6.46214H29.8933V7.35033H30.7694V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 5.4442H28.9V6.34237H29.7761V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M29.7761 4.43646H28.9V5.32465H29.7761V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 5.4442H26.9008V6.34237H27.7769V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 8.49762H26.9008V9.39579H27.7769V8.49762Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 6.46214H26.9008V7.35033H27.7769V6.46214Z"
            fill="#E8344E"
          />
          <path
            d="M26.7828 3.4285H25.8968V4.31669H26.7828V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M26.7828 5.4442H25.8968V6.34237H26.7828V5.4442Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 3.4285H26.9008V4.31669H27.7769V3.4285Z"
            fill="#E8344E"
          />
          <path
            d="M27.7769 4.43646H26.9008V5.32465H27.7769V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M26.7828 4.43646H25.8968V5.32465H26.7828V4.43646Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 13.0135H0.402466V13.9017H1.28846V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M4.28067 16.0471H3.40454V16.9353H4.28067V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 14.0215H0.402466V14.9097H1.28846V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M1.28846 15.0294H0.402466V15.9276H1.28846V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M4.28067 11.9959H3.40454V12.8941H4.28067V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 13.0135H1.40631V13.9017H2.28245V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 14.0215H1.40631V14.9097H2.28245V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M3.2871 16.0471H2.40112V16.9353H3.2871V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 16.0471H1.40631V16.9353H2.28245V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 11.9959H1.40631V12.8941H2.28245V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M2.28245 15.0294H1.40631V15.9276H2.28245V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M3.2871 11.9959H2.40112V12.8941H3.2871V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 16.0471H5.19659V16.9353H6.08257V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 15.0294H5.19659V15.9276H6.08257V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 13.0135H6.20099V13.9017H7.07713V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 14.0215H6.20099V14.9097H7.07713V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 11.9959H6.20099V12.8941H7.07713V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 10.9879H6.20099V11.8761H7.07713V10.9879Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 15.0294H6.20099V15.9276H7.07713V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 9.96997H6.20099V10.8681H7.07713V9.96997Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 10.9879H5.19659V11.8761H6.08257V10.9879Z"
            fill="#E8344E"
          />
          <path
            d="M7.07713 16.0471H6.20099V16.9353H7.07713V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 13.0135H5.19659V13.9017H6.08257V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 11.9959H5.19659V12.8941H6.08257V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 9.96997H5.19659V10.8681H6.08257V9.96997Z"
            fill="#E8344E"
          />
          <path
            d="M6.08257 14.0215H5.19659V14.9097H6.08257V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M13.012 11.9959H12.1359V12.8941H13.012V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M12.0184 11.9959H11.1423V12.8941H12.0184V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M12.0184 14.0215H11.1423V14.9097H12.0184V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M12.0184 16.0471H11.1423V16.9353H12.0184V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M12.0184 15.0294H11.1423V15.9276H12.0184V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M12.0184 13.0135H11.1423V13.9017H12.0184V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M13.012 13.0135H12.1359V13.9017H13.012V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M13.012 16.0471H12.1359V16.9353H13.012V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M13.012 14.0215H12.1359V14.9097H13.012V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M13.012 15.0294H12.1359V15.9276H13.012V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M10.0195 11.9959H9.14334V12.8941H10.0195V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M9.02537 13.0135H8.13937V13.9017H9.02537V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M9.02537 11.9959H8.13937V12.8941H9.02537V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M9.02537 14.0215H8.13937V14.9097H9.02537V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M10.0195 16.0471H9.14334V16.9353H10.0195V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M11.0237 16.0471H10.1378V16.9353H11.0237V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M9.02537 15.0294H8.13937V15.9276H9.02537V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M10.0195 13.0135H9.14334V13.9017H10.0195V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M10.0195 15.0294H9.14334V15.9276H10.0195V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M10.0195 14.0215H9.14334V14.9097H10.0195V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M18.1504 16.0471H17.2742V16.9353H18.1504V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M18.1504 15.0294H17.2742V15.9276H18.1504V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 9.96997H15.276V10.8681H16.1521V9.96997Z"
            fill="#E8344E"
          />
          <path
            d="M17.1564 11.9959H16.2704V12.8941H17.1564V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M17.1564 16.0471H16.2704V16.9353H17.1564V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M18.1504 11.9959H17.2742V12.8941H18.1504V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M19.1452 13.0135H18.269V13.9017H19.1452V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M19.1452 14.0215H18.269V14.9097H19.1452V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M18.1504 14.0215H17.2742V14.9097H18.1504V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M18.1504 13.0135H17.2742V13.9017H18.1504V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M19.1452 15.0294H18.269V15.9276H19.1452V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 10.9879H15.276V11.8761H16.1521V10.9879Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 14.0215H14.272V14.9097H15.158V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 11.9959H15.276V12.8941H16.1521V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 11.9959H14.272V12.8941H15.158V11.9959Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 15.0294H14.272V15.9276H15.158V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 16.0471H14.272V16.9353H15.158V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 10.9879H14.272V11.8761H15.158V10.9879Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 13.0135H14.272V13.9017H15.158V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 13.0135H15.276V13.9017H16.1521V13.0135Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 14.0215H15.276V14.9097H16.1521V14.0215Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 15.0294H15.276V15.9276H16.1521V15.0294Z"
            fill="#E8344E"
          />
          <path
            d="M16.1521 16.0471H15.276V16.9353H16.1521V16.0471Z"
            fill="#E8344E"
          />
          <path
            d="M15.158 9.96997H14.272V10.8681H15.158V9.96997Z"
            fill="#E8344E"
          />
        </svg>
      )}
    </LogoWrapper>
  );
}

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4.25px;
`;
