import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import isDesktopGnbFixed from "src/businesslogics/_common/utils/isDesktopGnbFixed";
import { useIsLoggedInV2 } from "@/businesslogics/_common/login";
import { useClickAnchor } from "@/businesslogics/_layout/gnb/v2";
import { showLineBannerAtom } from "@/stores/showLineBannerAtom";
import { GnbMyPageLogo } from "../../../atoms/_layout/gnb/v2/GnbMyPageLogo";
import { LineBanner } from "../../../organisms/_layout/LineBanner/LineBanner";
import {
  GnbAnchorDesktop,
  GnbTopAnchorDesktop,
} from "../atoms/GnbAnchor.desktop";
import GnbSpartaLogo from "src/components/atoms/Icons/SpartaLogo/GnbSpartaLogo";

import {
  mainAnchor,
  sccAnchor,
  topAnchors,
  topRightAnchors,
} from "@/models/_layout/gnbV3.models";
import { GnbMyPage } from "../molecules/GnbMyPage";
import { LoginButton } from "../molecules/LoginButton";
import * as S from "./Gnb.desktop.style";

export const GnbDesktopV3 = () => {
  const isLogin = useIsLoggedInV2();
  const isFixed = isDesktopGnbFixed();
  const onClickAnchor = useClickAnchor();
  const [showTopWrapper, setShowTopWrapper] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [showLineBanner] = useAtom(showLineBannerAtom);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    const isCodingland = window.location.pathname.includes("codingland");

    if (isCodingland) {
      if (currentScrollY < lastScrollY) {
        setShowTopWrapper(true);
      } else {
        setShowTopWrapper(false);
      }
    } else {
      setShowTopWrapper(true);
    }

    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);

  return (
    <>
      <S.TopWrapper
        fixed={isFixed}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
      >
        <S.InnerContainer>
          <S.TopGnbColumnContainer>
            {topAnchors.map((anchor) => {
              return (
                <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                  <S.CursorWrapper>
                    <GnbTopAnchorDesktop
                      key={`gnb-desktop-category${anchor.key}`}
                      anchor={anchor}
                      isCategory={true}
                    />
                  </S.CursorWrapper>
                </React.Fragment>
              );
            })}
          </S.TopGnbColumnContainer>
          <S.TopGnbColumnContainer>
            {topRightAnchors.map((anchor) => (
              <React.Fragment key={`gnb-desktop-category${anchor.key}`}>
                <S.CursorWrapper key={`gnb-desktop-category${anchor.key}`}>
                  <GnbTopAnchorDesktop
                    key={`gnb-desktop-category${anchor.key}`}
                    anchor={anchor}
                    isCategory={false}
                  />
                </S.CursorWrapper>
              </React.Fragment>
            ))}
          </S.TopGnbColumnContainer>
        </S.InnerContainer>
      </S.TopWrapper>
      <S.MainWrapper
        fixed={isFixed}
        show={showTopWrapper}
        showLineBanner={showLineBanner}
      >
        <S.InnerContainerBottom>
          <S.ColumnContainer>
            <S.LogoWrapper onClick={() => onClickAnchor(sccAnchor)}>
              <GnbSpartaLogo />
            </S.LogoWrapper>
            {mainAnchor.map((anchor) => (
              <GnbAnchorDesktop
                key={`gnb-desktop-category${anchor.key}`}
                anchor={anchor}
                isCategory={true}
              />
            ))}
          </S.ColumnContainer>
          <S.ColumnContainer>
            {isLogin ? (
              <>
                <GnbMyPageLogo />
                <GnbMyPage />
              </>
            ) : (
              <LoginButton />
            )}
          </S.ColumnContainer>
        </S.InnerContainerBottom>
        <LineBanner />
      </S.MainWrapper>
    </>
  );
};
