import { ComponentProps, useEffect, useState } from "react";
import * as S from "./tab.style";

type TabProps = {
  type?: "useUnderline" | "useBadge";
  initialSelectedTabLabel?: string;
  tabItems: TabItem[];
  fixedGnbHeight?: number;
};

export default function Tab({
  tabItems,
  initialSelectedTabLabel,
  type = "useUnderline",
  fixedGnbHeight,
}: TabProps) {
  const [selected, setSelected] = useState<any>(
    initialSelectedTabLabel || tabItems[0].label
  );

  const handleClick = (item: ComponentProps<typeof TabItem>) => {
    if (item.onClick) {
      item.onClick(item);
    }
    setSelected(item.label);
  };

  const TabContainer =
    type === "useBadge" ? S.BadgeTabContainer : S.UnderlineTabContainer;
  const TabItemComponent =
    type === "useBadge" ? S.BadgeTabItemContainer : S.UnderlineTab;

  useEffect(() => {
    setSelected(initialSelectedTabLabel);
  }, [initialSelectedTabLabel]);

  return (
    <TabContainer fixedGnbHeight={fixedGnbHeight}>
      {tabItems.map((item) => {
        return (
          <TabItemComponent
            key={item.label}
            isActive={item.label === selected}
            onClick={() => handleClick(item)}
          >
            {item.label}
          </TabItemComponent>
        );
      })}
    </TabContainer>
  );
}

interface TabItem {
  label: string;
  link?: string;
  selected?: boolean;
  onClick?: (anchor: any) => void;
  type?: "useUnderline" | "useBadge";
}

export function TabItem({
  type = "useUnderline",
  label,
  selected,
  onClick: handleClick,
}: TabItem) {
  const TabItemComponent =
    type === "useBadge" ? S.BadgeTabItemContainer : S.UnderlineTab;

  return (
    <TabItemComponent isActive={selected} onClick={handleClick}>
      {label}
    </TabItemComponent>
  );
}
