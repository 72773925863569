import { useUserId } from "@/businesslogics/_common/auth";
import { useEffect } from "react";
import { bootChannelTalk } from "./boot";
import { useRouter } from "next/router";

export const ChannelScript = () => {
  const userId = useUserId();
  const isChExcludedPathName = (pathName) =>
    [
      "/community/[channelName]/[category]",
      "/community/[channelName]/[category]/[postId]",
      "/community/[channelName]/[category]/[postId]/[title]",
      "/blog/[url_title]",
      "/app",
      "/app/lecturePage/[...url]",
      "/app/fastqnaEditPage/[...url]",
      "/app/fastqnaListPage/[...url]",
      "/app/fastqnaDetailPage/[...url]",
      "/coding1010",
      "/loginDone",
      "/aitc/apply",
      "/aitc/lt",
      "/aitc/lt_result",
      "/aitc",
    ].includes(pathName);

  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }
    if (!isChExcludedPathName(router.pathname)) {
      bootChannelTalk(userId);
    }
  }, [userId, router]);

  return (
    <script
      dangerouslySetInnerHTML={{
        __html: `(function () {
    var w = window;
    if (w.ChannelIO) {
        return (window.console.error || window.console.log || function () {
        })('ChannelIO script included twice.');
    }
    var ch = function () {
        ch.c(arguments);
    };
    ch.q = [];
    ch.c = function (args) {
        ch.q.push(args);
    };
    w.ChannelIO = ch;

    function l() {
        if (w.ChannelIOInitialized) {
            
            return;
        }
        w.ChannelIOInitialized = true;
        var s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://cdn.channel.io/plugin/ch-plugin-web.js';
        s.charset = 'UTF-8';
        var x = document.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
    }

    if (document.readyState === 'complete') {
        l();
    } else if (window.attachEvent) {
        window.attachEvent('onload', l);
    } else {
        window.addEventListener('DOMContentLoaded', l, false);
        window.addEventListener('load', l, false);
    }
})();`,
      }}
    />
  );
};

export default ChannelScript;
