export const communityChannelModels = [
  {
    name: "학습 질문",
    url: "fastqna",
  },
  {
    name: "개발 일지",
    url: "wil",
  },
  {
    name: "나의 활동",
    url: "myActivity",
    isLoginNeeded: true,
  },
];

export const blogChannel = {
  name: "블로그",
  url: "blog",
};

export const freeboardChannel = {
  name: "자유게시판",
  url: "freeboard",
};

export const fastqnaChannel = {
  name: "학습질문",
  url: "fastqna",
};

export const myActivityChannel = {
  name: "나의 활동",
  url: "myActivity",
  isLoginNeeded: true,
};

export const eventChannel = {
  name: "이벤트",
  url: "event",
};

export const exhibitionChannel = {
  name: "수강생 작품",
  url: "exhibition",
};

export const communityWilChannel = {
  name: "개발 일지",
  url: "wil",
};

export const mobileCommunityChannelModels = [
  blogChannel,
  fastqnaChannel,
  communityWilChannel,
  myActivityChannel,
  eventChannel,
  exhibitionChannel,
];

export const myActivityCategoryModels = [
  {
    keyword: "posts",
    title: "작성글",
    weeks: [],
    courseId: "0123",
  },
  {
    keyword: "bookmarks",
    title: "북마크",
    weeks: [],
    courseId: "1234",
  },
];

export const freeBoardCategoryModels = [
  {
    keyword: "best_post",
    title: "BEST 인기글",
    weeks: [],
    courseId: "5",
  },
  {
    keyword: "certification",
    title: "수강 인증",
    weeks: [],
    courseId: "6",
  },
  {
    keyword: "bunch_of_words",
    title: "사는 이야기",
    weeks: [],
    courseId: "1",
  },
  {
    keyword: "team_recruitment",
    title: "같이 해요",
    weeks: [],
    courseId: "2",
  },
  {
    keyword: "curious",
    title: "궁금해요",
    weeks: [],
    courseId: "3",
  },
  {
    keyword: "wil",
    title: "개발일지",
    weeks: [],
    courseId: "4",
  },
  {
    keyword: "etc",
    title: "기타",
    weeks: [],
    courseId: "5",
  },
];

export interface SelectorItem {
  title: string;
  keyword: string;
}

export const firstPostNotice = {
  title: "등록된 질문이 없어요.",
  subTitle: `첫 작성자가 되어보세요.\n튜터님들이 빠르게 답변해 드려요!`,
  thumbnail: "/assets/images/community/first-post-notice.png",
};

export const noSearchResult = {
  title: "검색 결과가 없어요.",
  subTitle: `질문을 직접 남기면\n더 정확한 해결 방법을 알 수 있어요!`,
  thumbnail: "/assets/images/community/no-search-result.png",
};

export const noPostNotice = {
  title: "아직 작성된 글이 없어요.",
  subTitle: `가장 먼저 글을 남겨보는 건 어때요?`,
  thumbnail: "/assets/images/community/write-nothing.png",
};

export const communityBanners = [
  {
    brand: "내일배움단",
    text: "인기강의를 국비지원으로 수강해보세요!",
    link: "https://spartacodingclub.kr/nb/guide",
    desktopSrc: "/assets/images/community/banner/banner_1.png",
    mobileSrc: "/assets/images/community/banner/banner_1_mo.png",
  },
  {
    brand: "인텔리픽",
    text: "오늘의 코딩 공부로 내일의 커리어를 바꾸세요.",
    link: "https://intellipick.spartacodingclub.kr",
    desktopSrc: "/assets/images/community/banner/banner_2.png",
    mobileSrc: "/assets/images/community/banner/banner_2_mo.png",
  },
];

export const communitySorterFilters = [
  { label: "최신순", value: "latest" },
  { label: "추천순", value: "best" },
  { label: "정확도순", value: "accuracy" },
];
